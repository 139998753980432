<template>
    <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
        <a href="#" @click.prevent="changeBoard()">
            <div class="card-block d-flex align-items-center flex-shrink-0">
                <div class="col-4">
                    <div class="card-block-logo flex-shrink-0">
                        <img :src="companyInfo.logo" alt="" class="img-fluid "/>
                    </div>
                </div>
                <div class="col-8">
                    <div class="project-name flex-shrink-0 p-3" data-toggle="tooltip" data-placement="bottom"
                         title="Dashboard">
                        <a href="#"><h3 class="p-1">{{ companyInfo.company_name }}</h3></a>
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
    import {useStore} from "vuex";
    import {useRouter} from 'vue-router';
    import HelperFunction from "@/common/helpers";

    export default {
        name: "SingleBoard",
        props: {
            companyInfo: {
                type: Object,
                required: false,
                default: () => {
                    return {
                        logo: require('@/assets/img/logo-herzing.png'),
                        company_name: 'Super Admin',
                        id: 0
                    }
                }
            }
        },
        setup(props) {
            const store = useStore();
            const router = useRouter();
            const {showToast} = HelperFunction();

            const changeBoard = () => {
                let payload = {
                    board: props.companyInfo,
                    route: '/dashboard'
                };
                store.dispatch('changeBoard', payload)
                    .then(res => {
                        router.push(payload.route).then(() => {
                            localStorage.removeItem('locale_change_key');
                            window.location.reload(); 
                        });
                    })
                    .catch(error => showToast(error));
            }

            return {
                changeBoard
            }
        }
    }
</script>

